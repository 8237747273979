<template>
	<div>
		<el-dialog :draggable="true" width="50%" top="5vh" v-model="outerVisible" @close="Reset" title="工会委员会">
			<div class="dialog no-bgs">
				<el-form ref="ruleForm" label-width="80px">
					<div class="title">
						<p>主席</p>
						<el-button @click="ghexport" size="mini" class="dialog-btn el-white" type="primary">导出Exl
						</el-button>
					</div>
					<el-form-item label="姓名">
						<span class="xx"></span>
						<el-input disabled clearable v-model="president.name" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="性别">
						<span class="xx"></span>
						<el-select disabled clearable v-model="president.sex" placeholder="">
							<el-option label="男" :value="1"></el-option>
							<el-option label="女" :value="0"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="民族">
						<span class="xx"></span>
						<el-input disabled clearable v-model="president.nation" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="联系电话">
						<span class="xx"></span>
						<el-input disabled clearable v-model="president.phone" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="行政职务">
						<span class="xx"></span>
						<el-input disabled clearable v-model="president.position" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="政治面貌">
						<span class="xx"></span>
						<el-input disabled clearable v-model="president.face" placeholder=""></el-input>
					</el-form-item>
					<div class="title">
						副主席
					</div>
					<el-form-item label="姓名">
						<span class="xx"></span>
						<el-input disabled clearable v-model="vice_president.name" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="性别">
						<span class="xx"></span>
						<el-select disabled clearable v-model="vice_president.sex" placeholder="">
							<el-option label="男" :value="1"></el-option>
							<el-option label="女" :value="0"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="民族">
						<span class="xx"></span>
						<el-input disabled clearable v-model="vice_president.nation" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="联系电话">
						<span class="xx"></span>
						<el-input disabled clearable v-model="vice_president.phone" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="行政职务">
						<span class="xx"></span>
						<el-input disabled clearable v-model="vice_president.position" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="政治面貌">
						<span class="xx"></span>
						<el-input disabled clearable v-model="vice_president.face" placeholder=""></el-input>
					</el-form-item>
					<div class="title">
						委员
					</div>
					<el-form-item label="姓名" v-for="(item,index) in three_member" :key="index">
						<span class="xx"></span>
						<el-input disabled clearable v-model="item.name" placeholder=""></el-input>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/word";
	export default {
		data() {
			return {
				outerVisible: false,
				president: {},
				vice_president: {},
				id:'',
				trade_id:'',
				three_member:[]
			}
		},
		methods: {
			// 导出
			ghexport() {
				this.$http.post(api.MemberExport,{
					trade_id:this.trade_id,
					elect_id:this.id
				})
					.then((res) => {
						window.location.href = res.data.url
					})
			},
			// 获取信息
			getTime(id) {
				this.$http.post(api.getElect, {
						trade_id: this.trade_id
					})
					.then((res) => {
						if (res.code == 200) {
							console.log(res.data)
							this.president = res.data.elect_viper.president
							this.vice_president = res.data.elect_viper.vice_president
							this.three_member = res.data.elect_viper.three_member
						}
					})
			},
			// 显示隐藏
			show(id,trade_id) {
				this.trade_id =trade_id
				this.id = id
				this.outerVisible = !this.outerVisible
				this.getTime()
			}
		}
	}
</script>

<style scoped lang="scss">
	.title {
		color: #000;
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		p {
			flex: 1;
		}
	}

	.imglist {
		width: 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		li {
			width: 20%;
			height: 100px;
			padding-right: 10px;
		}
	}
</style>
