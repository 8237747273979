<template>
	<div>
		<el-dialog :draggable="true" width="60%" top="5vh" v-model="outerVisible" @close="Reset" title="选举结果报告">
			<div class="dialog no-bgs">
				<el-form ref="ruleForm" label-width="150px">
					<el-form-item label="状态">
						<span class="xx"></span>
						<div>
							<el-tag v-if="ruleFormxj.status==0">审核中</el-tag>
							<el-tag type="success" v-if="ruleFormxj.status==1">已通过</el-tag>
							<el-tag v-if="ruleFormxj.status==2" type="danger">不通过</el-tag>
						</div>
					</el-form-item>

					<el-form-item label="不通过的原因" v-if="ruleFormxj.status==2">
						<span class="xx"></span>
						<el-input type="textarea" disabled clearable v-model="ruleFormxj.rejection_reason" placeholder="">
						</el-input>
					</el-form-item>

					<el-form-item label="选举时间">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleFormxj.time" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="工会委员会">
						<span class="xx"></span>
						<el-button @click="binmember" size="mini">查看详细</el-button>
					</el-form-item>
					<el-form-item label="经费审查委员会">
						<span class="xx"></span>
						<el-button @click="binfunds" size="mini">查看详细</el-button>
					</el-form-item>
					<el-form-item label="女职工委员会">
						<span class="xx"></span>
						<el-button @click="binworkers" size="mini">查看详细</el-button>
					</el-form-item>
					<el-form-item label="任期">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleFormxj.tenure" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="上传报告图片">
						<ul class="imglist">
							<li v-for="(item,index) in fuList" :key="index">
								<el-image style="width: 100%; height: 100%" :preview-src-list="fuList" :src="item" />
							</li>
						</ul>
					</el-form-item>
					<el-form-item label="上传报告Word/PDF">
						<div class="fu-list">
							<div class="fu-li" v-for="(item,index) in fuannex" :key="index">
								{{item.OriginalName}} 
								<el-button @click="download(item.url)" color="#488bff" class="el-white btn"
									style="color: white" size="mini">下载
								</el-button>
							</div>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
		<!-- 工会三委委员 -->
		<member ref="member"></member>
		<!-- 经费审查委员会主任 -->
		<funds ref="funds"></funds>
		<!-- 女职工委员会主任 -->
		<workers ref="workers"></workers>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/word";
	import member from './member.vue'
	import funds from './funds.vue'
	import workers from './workers.vue'
	export default {
		components: {
			member,
			funds,
			workers
		},
		data() {
			return {
				outerVisible: false,
				ruleFormxj: {},
				fuList: [],
				fuannex: [],
				id:''
			}
		},
		methods: {
			// 女职工委员会主任
			binworkers() {
				this.$refs.workers.show(this.ruleFormxj.id,this.ruleFormxj.trade_id)
			},
			// 经费审查委员会主任
			binfunds() {
				this.$refs.funds.show(this.ruleFormxj.id,this.ruleFormxj.trade_id)
			},
			// 工会三委委员
			binmember() {
				this.$refs.member.show(this.ruleFormxj.id,this.ruleFormxj.trade_id)
			},
			// 获取选举结果
			getElect(id) {
				this.$http.post(api.getElect, {
						trade_id: this.id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleFormxj = res.data
							if (res.data.pics) {
								this.fuList = res.data.pics.split(",")
							}
							if (res.data.annex) {
								this.fuannex =JSON.parse(res.data.annex)
							}

						}
					})
			},
			// 下载
			download(url) {
				window.location.href = url
			},
			// 显示隐藏
			show(id) {
				console.log(id)
				this.id = id
				this.outerVisible = !this.outerVisible
				this.getElect()
			}
		}
	}
</script>

<style scoped lang="scss">
	.title {
		font-size: 18px;
		color: #000;
		margin-bottom: 20px;

		div {
			width: 150px;
		}
	}

	.fu-li {
		width: 100%;
		font-size: 12px;
		height: 25px;
		margin-bottom: 10px;

		.btn {
			margin-left: 30px;
		}
	}

	.imglist {
		width: 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		li {
			width: 20%;
			height: 100px;
			padding-right: 10px;
		}
	}
</style>
