<template>
	<div>
		<el-dialog :draggable="true" width="50%" top="5vh" v-model="outerVisible" @close="Reset" title="女职工委员会">
			<div class="dialog no-bgs">
				<el-form ref="ruleForm" label-width="80px">
					<div class="title">
						<el-button @click="ghexport" size="mini" class="dialog-btn el-white" type="primary">导出Exl
						</el-button>
					</div>
					<div class="title">
						主任
					</div>
					<el-form-item label="姓名">
						<span class="xx"></span>
						<el-input disabled clearable v-model="women_workers.name" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="性别">
						<span class="xx"></span>
						<el-select disabled clearable v-model="women_workers.sex" placeholder="">
							<el-option label="男" :value="1"></el-option>
							<el-option label="女" :value="0"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="民族">
						<span class="xx"></span>
						<el-input disabled clearable v-model="women_workers.nation" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="联系电话">
						<span class="xx"></span>
						<el-input disabled clearable v-model="women_workers.phone" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="行政职务">
						<span class="xx"></span>
						<el-input disabled clearable v-model="women_workers.position" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="政治面貌">
						<span class="xx"></span>
						<el-input disabled clearable v-model="women_workers.face" placeholder=""></el-input>
					</el-form-item>
					<div class="title">
						副主任
					</div>
					<el-form-item label="姓名">
						<span class="xx"></span>
						<el-input disabled clearable v-model="women_vice.name" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="性别">
						<span class="xx"></span>
						<el-select disabled clearable v-model="women_vice.sex" placeholder="">
							<el-option label="男" :value="1"></el-option>
							<el-option label="女" :value="0"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="民族">
						<span class="xx"></span>
						<el-input disabled clearable v-model="women_vice.nation" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="联系电话">
						<span class="xx"></span>
						<el-input disabled clearable v-model="women_vice.phone" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="行政职务">
						<span class="xx"></span>
						<el-input disabled clearable v-model="women_vice.position" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="政治面貌">
						<span class="xx"></span>
						<el-input disabled clearable v-model="women_vice.face" placeholder=""></el-input>
					</el-form-item>
					
					<div class="title">
						委员
					</div>
					<el-form-item label="姓名" v-for="(item,index) in women_member" :key="index">
						<span class="xx"></span>
						<el-input disabled clearable v-model="item.name" placeholder=""></el-input>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/word";
	export default {
		data() {
			return {
				outerVisible: false,
				women_workers: {},
				id: '',
				trade_id: '',
				women_member: [],
				women_vice:{}
			}
		},
		methods: {
			// 导出
			ghexport() {
				this.$http.post(api.ElectViperExport, {
						post_type: 4,
						elect_id: this.id,
						trade_id: this.trade_id
					})
					.then((res) => {
						window.location.href = res.data.url
					})
			},
			// 获取信息
			getTime(id) {
				this.$http.post(api.getElect, {
						trade_id: this.trade_id
					})
					.then((res) => {
						if (res.code == 200) {
							this.women_workers = res.data.elect_viper.women_workers
							this.women_member = res.data.elect_viper.women_member
							this.women_vice = res.data.elect_viper.women_vice
						}
					})
			},
			// 显示隐藏
			show(id, trade_id) {
				this.trade_id = trade_id
				this.outerVisible = !this.outerVisible
				this.id = id
				this.getTime()
			}
		}
	}
</script>

<style scoped lang="scss">
	.title {
		color: #000;
		margin-bottom: 20px;
	}

	.imglist {
		width: 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		li {
			width: 20%;
			height: 100px;
			padding-right: 10px;
		}
	}
</style>
